.collection {
  padding: 20px 0 130px;
}

.collection .ui.header {
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
  padding-top: 90px;
}

.robotoken {
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
  padding-top: 40px !important;
  border-bottom: 2px solid #000000;
  margin-bottom: 120px !important;
}

.weAre {
  max-width: 500px;
}

.weAre::before {
  content: "";
  background-image: url("/public/icons/star-header.png");
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  position: absolute;
  margin-left: -100px;
  margin-top: 30px;
}

.collection .line-1 {
  top: 195px;
  left: 0px;
}

.collection .line-2 {
  top: 220px;
  left: 190px;
}

.robotoken-2 {
  position: relative;
}

.star-right {
  position: absolute !important;
  right: -35px;
  top: -60px;
}

.robotoken-img {
  background-image: url("/public/images/robotoken-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.robotoken-img img {
  margin-left: 50px;
}

.robotoken .ui.button {
  margin-bottom: 30px;
}

.collection h3.ui.header {
  font-weight: bold;
  font-size: 32px;
  line-height: 180%;
  padding-top: 40px;
  padding-bottom: 60px;
}

.collection .lines {
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  text-align: left;
  background-position: 90px top;
  text-transform: none;
  min-height: unset;
  background-size: 734px 763px;
}

.collection .owners {
  max-width: 500px;
  padding-bottom: 16px;
}

.collection .owners::after {
  content: "";
  background-image: url("/public/images/red-lines.png");
  background-repeat: no-repeat;
  width: 693px;
  height: 58px;
  position: absolute;
  margin-left: -80px;
  margin-top: 370px;
}

.collection .change {
  font-weight: 800;
  font-size: 53px;
  line-height: 65px;
  text-transform: uppercase;
  padding-top: 160px;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .collection {
    padding: 20px 0 20px;
  }

  .collection .ui.header {
    font-size: 32px;
    line-height: 39px;
    text-align: left;
    padding-top: 40px;
  }

  .robotoken {
    font-size: 20px;
    line-height: 180%;
    padding-top: 30px !important;
    margin-bottom: unset !important;
  }

  .collection h3.ui.header {
    font-size: 24px;
    line-height: 180%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collection .lines.column {
    font-size: 20px;
    line-height: 160%;
    text-transform: unset;
  }

  .collection .lines .change {
    font-size: 32px;
    line-height: 39px;
    padding-top: 110px;
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .robotoken-img {
    display: block;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .robotoken-img img {
    width: 80%;
  }
}
@media (max-width: 1910px) {
  .star-right,
  .weAre::before {
    display: none !important;
  }
}

.animate {
  position: relative;
}

.l1,
.l2,
.l3,
.l4,
.l5,
.l6,
.l7,
.l8,
.l9,
.l10 {
  width: 80%;
  height: 8%;
  margin-top: 5px;
  background-color: rgba(220, 220, 220, 0.6);
  position: absolute;
}

.l1 {
  top: 0;
  animation: moving-1 4000ms linear infinite;
}
.l2 {
  top: 10%;
  animation: moving-2 4000ms linear infinite;
}
.l3 {
  top: 20%;
  animation: moving-1 3000ms linear infinite;
}
.l4 {
  top: 30%;
  animation: moving-2 6000ms linear infinite;
}
.l5 {
  top: 40%;
  animation: moving-1 4000ms linear infinite;
}
.l6 {
  top: 50%;
  animation: moving-2 4000ms linear infinite;
}
.l7 {
  top: 60%;
  animation: moving-1 2000ms linear infinite;
}
.l8 {
  top: 70%;
  animation: moving-2 4000ms linear infinite;
}
.l9 {
  top: 80%;
  animation: moving-1 3000ms linear infinite;
}
.l10 {
  top: 90%;
  animation: moving-2 5000ms linear infinite;
}

@keyframes moving-1 {
  from {
    transform: translateX(-5%);
  }
  50% {
    transform: translateX(30%);
  }
  to {
    transform: translateX(-5%);
  }
}

@keyframes moving-2 {
  from {
    transform: translateX(30%);
  }
  50% {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(30%);
  }
}
