.miles {
  padding-top: 110px;
}

.miles .column {
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
}

.miles h2.ui.header {
  text-align: left;
  padding-bottom: 50px;
}

.release {
  margin-top: 45px;
}

.blockquote-col {
  padding-bottom: 70px !important;
}

blockquote {
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  padding-left: 100px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.miles .line-2 {
  margin-left: 250px;
}

.miles .tabs .column {
  font-weight: 800 !important;
  font-size: 24px;
}

.miles .tabs {
  background-image: url("/public/images/hatching.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 100px !important;
}

.colored .looking {
  padding-top: 50px;
}

.weIntend {
  padding-top: 25px;
}

.weIntend,
.everything {
  font-weight: 100 !important;
  font-size: 20px !important;
  line-height: 200.02% !important;
}

.everything {
  font-weight: 500;
  font-size: 20px;
  line-height: 200.02%;
  text-transform: uppercase;
}

.up {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .miles {
    padding-top: 50px;
  }

  .miles .column {
    font-size: 18px;
    line-height: 180%;
  }

  blockquote {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    margin-block-end: 0;
  }

  .colored .looking {
    padding-top: 20px;
  }

  blockquote {
    padding-left: 20px;
  }

  .miles .ui.container > .colored.ui.stackable.grid > .column {
    padding: 30px 40px !important;
  }
}
