.countdown {
  padding-top: 50px;
  background-image: url("/public/images/bg-countdown.png");
  background-position: bottom center;
  background-repeat: no-repeat;
}

.countdown_launch {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-top: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
  text-align: left;
}

.countdown_launch > img {
  margin: 35px 20px !important;
}

.countdown_launch > div {
  flex-grow: 1;
  width: 100%;
}

.countdown_launch .step {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.countdown .flipUnitContainer {
  margin: 0 !important;
}

.rocket {
  position: absolute !important;
  right: -40px;
  top: 100px;
}

.rSpace {
  font-size: 24px;
  line-height: 180%;
  border-top: 2px solid #000000;
  margin-top: 80px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 120px;
  background-image: url("/public/images/hatching.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: -20px;
}

.col-dark,
.col-white {
  padding: 40px 60px !important;
}

.col-dark {
  font-weight: 500;
  font-size: 20px;
  line-height: 200%;
  background-color: #262626;
  color: #fff;
}

.col-white {
  font-weight: bold;
  font-size: 20px;
  line-height: 180%;
}

.col-dark div,
.col-white div {
  font-weight: 800;
  font-size: 24px;
  line-height: 200%;
}

.countdown h3.ui.header {
  text-align: left;
  margin-top: 120px;
}

.staticFlip,
.staticFlipText {
  background-color: var(--color-card);
  border-radius: 10px;
  box-shadow: 0px 10px 10px -10px grey;
  width: 50%;
  height: 120px;
  color: #fff;
  font-family: "Major Mono Display", monospace;
  font-size: 110px;
  line-height: 100%;
  text-align: center;
  position: relative;
}

.staticFlipText {
  font-family: "Montserrat", sans-serif;
  font-size: 42px;
  line-height: 120px;
  width: 100%;
}

.staticFlip::before {
  content: "";
  background-color: rgba(100, 100, 100, 0.7);
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
}

.staticFlip::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  background-image: url("/public/icons/star-launch.png");
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 1900px) {
  .rocket {
    right: 10px;
    top: 100px;
  }
}

@media (max-width: 768px) {
  .countdown .ui.container > .ui.stackable.grid > .column {
    padding: 30px 40px !important;
  }

  .countdown_launch {
    flex-direction: column;
    align-items: center;
  }

  .staticFlip::after {
    width: 0%;
    display: none;
  }

  .staticFlip,
  .staticFlipText {
    width: 100%;
  }

  .rocket {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .countdown_launch {
    font-size: 16px;
    line-height: 180%;
  }

  .countdown_launch .step {
    font-size: 16px;
    line-height: 160%;
  }

  .staticFlip {
    font-size: 72px;
    line-height: 82px;
    height: 90px;
  }

  .staticFlipText {
    font-size: 32px;
    line-height: 90px;
    height: 90px;
  }
}
