.content-base, .modal {
  background-image: url("/public/bg100.png");
  background-repeat: repeat;
  background-color: #fff;
  min-width: 350px;
  border-radius: 10px;
  margin: 10px;
}

.modal, .ui.input>input {
  text-align: center !important;
}

.modal-title {
  text-align: center;
}
