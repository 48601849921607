@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Montserrat:ital,wght@0,600;0,700;0,800;1,500;1,600&display=swap");
body {
  margin: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/bg100.png");
  background-repeat: repeat;
}

ul {
  padding-inline-start: 0px;
}

li {
  list-style: none;
  text-align: left;
  background: url("/public/icons/plus.png") no-repeat 0 6px;
  padding-left: 54px;
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
}

li:not(:last-child) {
  margin-bottom: 30px;
}

.dark {
  background: #262626;
  color: #fff;
}

.ui.container {
  font-size: 14pt;
  max-width: 1640px !important;
  width: 100%;
}

h2.ui.header,
h3.ui.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 53px;
  line-height: 65px;
  text-transform: uppercase;
}

.ui.header.lined {
  padding: 40px 0;
  border-top: 3px solid #000000;
  border-bottom: 3px solid #000000;
}

.ui.header.star,
.ui.header.star-white {
  padding-left: 100px;
  position: relative;
}

.ui.header.star::before {
  content: "";
  background-image: url("/public/icons/star-header.png");
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  position: absolute;
  margin-left: -100px;
  margin-top: 4px;
}

.ui.header.star-white::before {
  content: "";
  background-image: url("/public/icons/star-header-white.png");
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  position: absolute;
  margin-left: -100px;
  margin-top: 4px;
}

.ui.button {
  background: #e93435;
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  color: #ffffff;
  padding: 40px 160px;
}

.ui.button:hover {
  background: #1e201f;
  color: #ffffff;
}

.ui.celled.grid {
  box-shadow: 0 0 0 2px #000;
}

.ui.celled.grid > .row {
  box-shadow: 0 -2px 0 0 #000;
}

.ui.celled.grid > .row > .column {
  box-shadow: -2px 0 0 0 #000;
}

strong {
  color: #e62e2d;
}

.ui.grid {
  margin-left: unset;
  margin-right: unset;
}

svg {
  margin: 0 10px;
}

svg:hover #filling  {
    fill: #e62e2d;
    transition: all 0.2s ease 0s;
}

@media (max-width: 768px) {
  h2.ui.header,
  h3.ui.header {
    font-size: 32px;
    line-height: 39px;
  }

  li {
    font-size: 18px;
    line-height: 160%;
  }

  .ui.header.star,
  .ui.header.star-white {
    padding-left: 60px;
    position: relative;
    text-align: left;
  }

  .ui.header.star::before,
  .ui.header.star-white::before {
    width: 35px;
    height: 35px;
    background-size: contain;
    margin-left: -65px;
    margin-top: 15px;
  }

  .ui.header.lined {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 1920px) {
  .ui.container {
    width: calc(100% - 20px) !important;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .ui.container {
    width: auto !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media (max-width: 1200px) {
  .ui.button {
    padding: 40px 100px;
  }
}

@media (max-width: 640px) {
  .ui.button {
    font-size: 32px;
    line-height: 120%;
    padding: 40px 10px;
    width: 100%;
  }
}
