.road {
  padding-top: 30px;
}

.road .sub.header {
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
}

.road .ui.grid {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  margin-top: 30px;
}

.lines {
  background-image: url("/public/images/bg-lines.png");
  background-position: left top;
  background-repeat: no-repeat;
  min-height: 650px;
  padding-top: 100px;
  font-weight: 800;
  font-size: 53px;
  line-height: 65px;
  text-transform: uppercase;
  padding-top: 160px !important;
  padding-left: 0 !important;
}

.road .line-1 {
  top: -65px;
  left: 30px;
}

.road .line-2 {
  top: 180px;
  right: 70px;
}

.road__mint {
  position: relative;
}

@media (max-width: 1200px) {
  .road .lines {
    font-size: 38px;
  }

  .road .line-2 {
    left: 0;
    right: unset;
  }
}

@media (max-width: 768px) {
  .road .sub.header {
    font-size: 20px;
    line-height: 180%;
    text-align: left;
    padding-top: 20px;
  }

  .lines {
    font-size: 38px;
    background-size: cover;
    background-position: center;
    min-height: 500px;
  }

  .road__mint {
    min-height: 250px;
    margin-top: 100px !important;
  }

  .road .line-2 {
    left: unset;
    right: 70px;
  }
}
