.about {
  padding-top: 125px;
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
}

.about h2.ui.header {
  margin-bottom: 60px;
}

.about h3.ui.header {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.about__list {
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
  text-transform: uppercase;
  padding: 40px 0 30px;
}

.about__picture {
  background-image: url("/public/images/bg-about.png");
  background-position: -10px 25px;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: right;
}

.about__picture img {
  margin-top: 120px;
  margin-right: 0 !important;
  margin-left: auto;
}

.moving-image {
  display: none;
}

@media (max-width: 768px) {
  .about {
    padding-top: 45px;
    font-size: 18px;
    line-height: 180%;
  }

  .about h2.ui.header {
    margin-bottom: 30px;
  }

  .about h3.ui.header {
    font-size: 24px;
    line-height: 29px;
  }

  .about__list {
    font-size: 20px;
    line-height: 180%;
  }
}

@media (max-width: 991px) {
  .moving-image {
    margin-top: 40px;
    display: block;
    background-image: url("/public/images/bg-about-mobile.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .moving-image img {
    width: 80%;
  }
}
