.tab {
  border-top: solid 2px #262626;
  background-image: url("/public/icons/tab.png");
  background-position: left top;
  background-repeat: no-repeat;
  text-align: left;
  height: 57px;
  line-height: 57px;
  color: #ebedf1;
  padding-left: 40px;
  margin-top: 35px;
  margin-bottom: 35px;
}

header img,
footer img {
  width: 52px;
  margin: 0 10px;
}

.App {
  text-align: center;
  padding-bottom: 40px;
  overflow: hidden;
}

header {
  margin-top: 40px;
  height: 110px;
  margin-bottom: 60px;
}

.menu-header,
.footer {
  min-height: 115px;
  padding-left: 30px !important;
  background-image: url("/public/images/header.png");
  background-position: 20% 10px;
  background-repeat: no-repeat;
}

.footer {
  padding-top: 2px;
  margin-top: 30px;
}

.footer::before {
  content: "";
  background-image: url("/public/images/red-lines.png");
  background-repeat: no-repeat;
  width: 693px;
  height: 58px;
  position: absolute;
  margin-left: 60px;
  margin-top: -40px;
}

.musk {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  padding-top: 35px;
  padding-bottom: 5px;
}

.community {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
}

.from {
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding-left: 20px;
  text-transform: none;
}

.from b {
  font-style: normal;
}

.space-bt {
  display: flex;
  justify-content: space-between;
}

.space-bt > div {
  flex-grow: 1;
}

.v-center {
  align-items: center;
}

.view-mobile {
  display: none;
}

.view-desktop {
  display: block;
}

.copyright {
  font-weight: 900;
  padding-left: 40px;
}

.text-left {
  text-align: left;
}

.menu-elements {
  text-align: right;
}

.menuButton {
  padding-top: 5px;
  display: none !important;
}

.menuButton:hover {
  cursor: pointer;
}

.line {
  background-color: #b6b6b6;
  width: 400px;
  height: 13px;
  position: absolute;
}

.view-mobile-1 {
  display: none;
}

.sidebar {
  padding: 20px;
  background-color: #eee !important;
}

.menu-elements {
  text-align: right;
  padding-top: 10px;
}

.no-scroll {
  height: 100vh !important;
  overflow-y: hidden;
}

@media (max-width: 1910px) {
  .footer::before {
    display: none;
  }
}

/* special for footer */

@media (max-width: 1550px) {
  .view-mobile-1 {
    display: block;
    text-align: center;
    margin-top: 40px !important;
  }

  .view-desktop-1 {
    display: none !important;
  }

  .copyright {
    padding-left: 0;
  }

  footer .social {
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  header {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  header .social {
    display: none;
  }

  .view-mobile {
    display: block;
  }

  .view-desktop {
    display: none;
  }

  .tab {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .menu-header,
  .footer {
    background-position: -420px 14px;
    padding-left: 10px !important;
  }

  .musk {
    font-size: 12px;
    line-height: 15px;
  }
  .community {
    font-size: 10px;
    line-height: 12px;
  }

  .from {
    font-size: 10px;
    line-height: 12px;
    display: block;
    padding-left: 0;
    padding-top: 5px;
  }
}

@media (max-width: 1520px) {
  .footer .space-bt {
    display: block;
    text-align: right;
  }
}

@media (max-width: 1100px) {
  header .social {
    display: none;
  }

  .menuButton {
    display: inline-block !important;
  }
}

@media (max-width: 550px) {
  .menu-header {
    background-image: url("/public/images/header-mobile.png");
  }

  .menuButton {
    padding-top: 20px;
    width: 40px;
  }
}
