.robots {
  background-color: #262626;
  color: #fff;
  padding: 65px 0;
}

.robots .ui.header {
  color: #fff;
}

.robots .sub.header {
  padding: 0 0 70px;
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
}

.robots__name {
  padding-top: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  padding-bottom: 10px;
}

.ui.grid.bordered {
  box-shadow: 0 0 1px 1px #d4d4d5 !important;
}

.bordered .column {
  box-shadow: 1px 1px #d4d4d5 !important;
  padding-bottom: 20px !important;
}

.bordered {
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
}

.bordered img {
  margin-top: 20px !important;
}

.earth {
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
}

.earth-img {
  margin-top: 40px;
}

.earth .header {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  margin-top: 120px;
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .robots {
    padding: 45px 0;
  }

  .robots .column {
    font-size: 12px;
    line-height: 180%;
    font-style: normal;
  }
  .robots img {
    width: 80%;
  }

  .robots .sub.header {
    padding: 30px 0 50px;
    font-size: 20px;
    line-height: 180%;
    text-align: left;
  }

  .robots__name {
    font-size: 14px;
    line-height: 160%;
  }

  .earth {
    font-size: 18px !important;
    line-height: 180%;
    font-weight: 500 !important;
  }

  .earth .header {
    font-size: 24px;
    line-height: 29px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .earth-img {
    text-align: center !important;
    margin-top: 30px;
    width: 100% !important;
    max-width: 300px !important;
  }
}
