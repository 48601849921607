.why {
  padding: 50px 0 140px;
}

.why .ui.header {
  padding: 44px 0;
}

.why__lines {
  background-image: url("/public/images/bg-why.png");
  background-position: center -3px;
  background-repeat: no-repeat;
  height: 90px;
}

.why__content {
  padding: 50px !important;
  font-weight: 800;
  font-size: 24px;
  line-height: 200%;
  text-transform: uppercase;
  background-image: url("/public/images/hatching.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 80px !important;
}

.why__first {
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
  padding: 60px 90px !important;
}

.why__first .header {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.why__among {
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
  border-bottom: solid 2px #000;
  padding-bottom: 40px !important;
  margin-top: 40px !important;
}

.why__among .header {
  font-weight: 800;
  font-size: 53px;
  line-height: 65px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .why .ui.header {
    padding: 30px 0 20px;
    border-top: solid #000 2px;
  }

  .why__content {
    font-size: 20px;
    line-height: 200%;
    padding: 20px !important;
  }

  .ui.container > .ui.stackable.grid > .row > .column.why__first {
    font-size: 18px;
    line-height: 180%;
    padding: 30px 20px !important;
  }

  .why .ui.stackable.celled.grid > .row,
  .why .ui.stackable.celled.grid > .row > .column {
    box-shadow: 0 -2px 0 0 #000 !important;
    border-top: none !important;
  }

  .why__first .header {
    font-size: 24px;
    line-height: 29px;
  }

  .why__among {
    font-size: 20px;
    line-height: 180%;
    margin-top: 30px !important;
  }

  .why__among .header {
    font-size: 32px;
    line-height: 39px;
    padding-bottom: 30px;
  }
}
