.explore {
  background-image: url("/public/images/bg-explore.png");
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 50px;
}

.explore .column {
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
}

.explore__nft {
  margin-bottom: 35px;
}

.ready {
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  color: #262626;
  padding-top: 45px;
  padding-bottom: 40px;
}

.explore__icons {
  margin-bottom: 230px;
}

.explore__first {
  font-weight: bold;
  font-size: 24px;
  line-height: 180%;
  padding-top: 50px;
  padding-bottom: 30px;
}

.unique {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  padding-top: 60px;
  text-align: left;
}

.hints {
  position: relative;
}

.hint {
  position: absolute;
}

.hint img {
  cursor: pointer;
}

.hint-body {
  display: none;
  position: absolute;
  background: rgba(254, 255, 255, 0.9);
  box-shadow: 15px 12px 23px rgba(72, 59, 124, 0.03);
  backdrop-filter: blur(23px);
  border-radius: 20px;
  width: 350px;
  z-index: 1;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.025em;
  padding: 30px 30px 40px;
  left: -150px;
  bottom: 120%;
}

.hint-body::after {
  content: "";
  position: absolute;
  left: 155px;
  border: 10px solid transparent;
  border-top: 10px solid rgba(254, 255, 255, 0.9);
  bottom: -19px;
}

.hint-name {
  font-weight: bold;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.04em;
  padding-bottom: 15px;
}

.hint:hover .hint-body {
  display: block;
}

.hint-code {
  top: 40%;
  left: 18%;
}

.hint-signature {
  top: 73%;
  left: 25%;
}

.hint-year {
  top: 78%;
  left: 76%;
}

.hint-serial {
  top: 36%;
  left: 84%;
}

.explore h3 {
  max-width: 650px;
}

@media (max-width: 768px) {
  .ready {
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .explore {
    font-size: 18px;
    line-height: 180%;
  }

  .explore__nft {
    padding-top: 30px;
  }

  .hints {
    margin-top: 20px;
  }

  .explore__icons {
    margin-bottom: 60px;
  }

  .explore__first {
    padding-top: 20px;
  }

  .unique {
    padding-top: 40px;

    font-size: 24px;
    line-height: 29px;
  }
}

@media (max-width: 1500px) {
  .hint-body {
    border-radius: 16px;
    width: 240px;
    font-size: 14px;
    padding: 20px 20px 30px;
    left: -100px;
    bottom: 120%;
  }

  .hint-body::after {
    left: 105px;
    bottom: -19px;
  }

  .hint-code {
    top: 45%;
    left: 22%;
  }

  .hint-year {
    top: 75%;
    left: 63%;
  }

  .hint-serial {
    top: 36%;
    left: 68%;
  }
}
